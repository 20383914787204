.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 100px auto 65px;
  width: 60%;
  transition: width .25s;
  justify-content: center;
}

.error {
  border: 1px dashed red;
  background-color: rgba(205, 92, 92, 0.5);
  padding: 10px;
  margin-bottom: 16px;
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .container {
    width: 75%;
  }
}

@media screen and (max-width: 1199px) and (min-width: 1000px) {
  .container {
    width: 85%;
  }
}

@media screen and (max-width: 999px) and (min-width: 799px) {
  .container {
    width: 90%;
    margin: 50px auto 55px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 100%;
    margin: 50px auto 55px;
  }
}
