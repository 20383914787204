.header {
  background-color: lightslategray;
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 100;
  padding: 10px 40px;
  box-shadow: 1px 1px 1px 1px #cccccc;
  display: flex;
  flex-direction: column;
  transition: all .2s ease;

  .title {
    font-family: "Segoe UI", monospace;
    font-weight: lighter;
    font-size: 20px;
    color: #f5f5f5;
    user-select: none;

    display: inline-flex;
    align-items: center;
    gap: 4px;

    & > span:nth-child(2) {
      font-size: 14px;
      align-self: flex-start;
      color: #274141;
    }
  }

  .dropdown {
    border-radius: 4px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    padding: 4px 2px;
    width: 135px;
    height: 24px;
  }

  .linkButton {
    opacity: 1;
    -webkit-transition: opacity 600ms;
    -moz-transition: opacity 600ms;
    transition: opacity 600ms;
    user-select: none;
    user-focus: none;
    background: none;
    color: ghostwhite;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    font-size: 14px;

    &:hover:enabled {
      color: #dfdfdf;
    }

    &:disabled {
      user-input: none;
      user-select: none;
      cursor: auto;
      opacity: 0.5;
      -moz-transition: opacity 600ms;
      -webkit-transition: opacity 600ms;
      transition: opacity 600ms;
    }
  }

  .mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .centerBlock {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }

    .leftBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: max(220px, calc(20vw - 50px));
    }

    .rightBlock {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }
  }

  .subHeader {
    padding-top: 10px;
    padding-bottom: 5px;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: flex-end;
  }

  .languages, .viewModes {
    height: 24px;
  }

  .menuButtonContainer {
    display: none;
  }

  .searchBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    border-radius: 4px;
    background: white;
    width: 100%;
    height: 24px;
    max-width: max(min(1024px, 45vw), 715px);
  }

  @media screen and (max-width: 1000px) {
    height: auto;
    position: relative;

    .mainHeader {
      width: calc(100% - 30px);
    }

    .languages, .viewModes {
      display: inline-block;
      color: ghostwhite;
      user-select: none;
    }

    .menuButtonContainer {
      top: 0;
      right: 0;
      display: flex;
      position: absolute;
      padding-right: 10px;
      align-items: center;
      justify-content: center;
      height: 40px;

      input[type=checkbox] {
        display: none;
      }

      .menuButton {
        color: whitesmoke;
        font-weight: bold;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .searchBar {
      max-width: unset;
    }

    .mainHeader {
      flex-direction: column;
      gap: 5px;

      .leftBlock {
        display: none;
      }

      .rightBlock, .centerBlock {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        gap: 5px;
      }
    }

    .languages, .viewModes {
      padding-left: 0;
    }

    .subHeader {
      display: flex;
      width: 100%;
      align-items: center;
      padding-top: 10px;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
    }

    .dropdown {
      min-width: 200px;
      height: 20px;
      padding: 0;
    }

    .linkButton {
      border: 1px solid whitesmoke;
      padding: 2px;
      border-radius: 4px;
      font-size: 12px;
      background-color: whitesmoke;
      color: black;
      display: flex;
      justify-content: center;
      margin-left: 0;
      width: 70px;
      height: 45px;
      align-items: center;
    }
  }
}
