.entry {
  display: block;
  padding: 20px 30px;
  height: auto;

  &:nth-of-type(even) {
    background-color: rgba(188, 214, 241, 0.2);
  }

  &.highlighted {
    box-shadow: 0 0 0 2px rgba(255, 255, 0, 0.5);
  }

  .dropdown {
    font-size: 12px;
    color: darkslategrey;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;

    &:hover {
      color: darkslateblue;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .languageName {
    color: #060606;
    font-size: 12px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    height: 18px;

    justify-content: space-between;
  }

  .sourceLanguage, .targetLanguage {
    width: 100%;
  }

  .languages label {
  }

  .sourceLanguage .textArea, .entry .targetLanguage .textArea {
    width: 100%;
    box-sizing: border-box;
  }

  .headTextAreas {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    gap: 20px;
  }

  .editors {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: calc(50% - 10px);

    &.languages {
      max-width: 100%;
    }
  }

  input.toggle[type=checkbox] {
    display: none;
  }

  input.entryCheckBox[type=checkbox] {
    vertical-align: middle;
    transform: scale(1.275);
    cursor: pointer;
    margin-right: 10px;
    padding: 0;
  }

  .labelToggle {
    cursor: pointer;
    user-select: none;
    font-size: 14px;

    &:hover {
      color: darkslateblue;
    }

    &::before {
      content: ' ';
      display: inline-block;

      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid currentColor;

      vertical-align: middle;
      margin-right: .4rem;
      transform: translateY(-2px);

      transition: transform .2s ease-out;
    }
  }

  .collapsibleContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s ease-in-out;

    .textArea {
      resize: vertical;
    }
  }

  .toggle:checked + .labelToggle + .collapsibleContent,
  .toggle:checked + .descriptionToggle + .collapsibleContent {
    max-height: max-content;
  }

  .toggle:checked + .labelToggle::before,
  .toggle:checked + .descriptionToggle::before {
    transform: rotate(90deg) translateX(-3px);
  }

  .toggle:checked + .labelToggle,
  .toggle:checked + .descriptionToggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .collapsibleInner {
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .footerToolbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: sans-serif;
    font-size: 12px;
    color: #41572b;

    span {
      height: 25px;
      display: inline-flex;
      align-items: center;
      white-space: pre-wrap;
      padding: 0;
    }
  }

  .rightFooterToolbox {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }

  .footerToolbox .leftFooterToolbox * + .footerToolbox .leftFooterToolbox *,
  .footerToolbox .rightFooterToolbox * + .footerToolbox .rightFooterToolbox * {
    margin-right: 16px;
  }

  .headerToolbox {
    display: flex;
    justify-content: flex-end;
  }
}

.warnLength {
  color: indianred;
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .entry {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
  }

  .highlighted {
    margin-left: 4px;
    margin-right: 4px;
  }

  .entry .headTextAreas {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  .entry .editors {
    min-width: 100%;
    max-width: 100%;
  }

  .entry .sourceLanguage + .targetLanguage {
    margin: 0;
  }

  .fullscreenExitLabel {
    top: 3.75vh;
  }
}
