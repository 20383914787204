.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 767px) {
    width: unset;
  }

  .group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .select {
    font-size: 18px;
    width: 100%;
    max-width: 200px;
    cursor: pointer;
  }

  .input {
    font-size: 18px;
    width: 100%;
  }

  textarea.input {
    outline: none;
    resize: none;
    height: 150px;
  }
}
