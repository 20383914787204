.authPage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.authPage .title {
    font-family: "Segoe UI", monospace;
    user-select: none;
    font-weight: lighter;
    font-size: 24px;
    color: #223e3e;
    margin-bottom: 24px;
}

.authPage .authForm {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 25vw;
    padding: 32px 32px;
    flex-direction: column;
    border: 1px solid rgba(47, 79, 79, 0.5);
    border-radius: 5px;
}

.authPage .loginInput, .passwordInput, .loginButton {
    display: block;
    margin: auto auto 16px;
    font-family: monospace;
    font-size: 18px;
    width: 100%;
}

.authPage .loginButton {
    border: none;
    padding: 8px;
    cursor: pointer;
    user-select: none;
}

.authPage .loginButton:hover {
    background-color: lightgray;
}

.authPage .error {
    border: 1px dashed red;
    background-color: rgba(205, 92, 92, 0.5);
    padding: 10px;
    margin-bottom: 16px;
}

@media screen and (max-width:800px) {
    .authPage .authForm {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 65vw;
        padding: 32px 32px;
        flex-direction: column;
        border: 1px solid rgba(47, 79, 79, 0.5);
    }
}