.container {
  max-width: calc(100% - 50px);
}

.key {
  font-size: 14px;
  font-family: "Courier New", monospace;
  color: darkslateblue;
  font-weight: bold;
  cursor: copy;
  word-wrap: break-word;
  word-break: break-all;
  display: inline;
  padding-right: 10px;

  &:active {
    color: #863c50;
  }
}
