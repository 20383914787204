.entryList {
    width: 100%;
    border: 1px solid rgba(47, 79, 79, 0.25);
    box-shadow: 1px 1px 10px rgba(119, 136, 153, 0.2);
    height: 100%;
    min-height: 85vh;
}

.pageActions {
    background-color: rgba(255, 255, 255, 0.8);
    position: sticky;
    top:80px;
    z-index: 99;
    padding: 5px;
    opacity: 1;
    -webkit-transition: opacity 400ms;
    -moz-transition: opacity 400ms;
    transition: opacity 400ms;
}

.pageActions:empty
{
    opacity: 0;
    padding: 0;
}

.actionButton {
    font-size: 12px;
    padding: 6px 10px;
    margin-bottom: 0;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: rgb(72, 61, 139);
    color: white;
    margin-right: 5px;
    margin-left: 5px;
}

.actionButton:hover {
    color: white;
    text-decoration: none;
}

.actionButton:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.loading {
    margin: auto;
}

@media screen and (max-width:1000px)  {
    .pageActions {
        top:40px;
        z-index: 99;
        padding: 5px;
    }

    .entryList {
        border: none;
        box-shadow: none;
    }
}