$screenPhone: 480px;
$screenTablet: 768px;
$screenDesktop: 960px;

$screenPhoneMin: ($screenPhone - 1);
$screenPhoneMax: ($screenTablet - 1);
$screenTabletMax: ($screenDesktop - 1);

@mixin media-cellphones {
  @media (max-width: $screenPhoneMin) {
    @content;
  }
}

@mixin media-smartphones {
  @media (min-width: $screenPhone) and (max-width: $screenPhoneMax) {
    @content;
  }
}

@mixin media-all-phones {
  @media (max-width: $screenPhoneMax) {
    @content;
  }
}

@mixin media-not-cellphones {
  @media (max-width: $screenPhoneMax) and (min-width: $screenPhone) {
    @content;
  }
}

@mixin media-tablets {
  @media (min-width: $screenTablet) and (max-width: $screenTabletMax) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: $screenTabletMax) {
    @content;
  }
}

@mixin media-tablet-and-desktop {
  @media (min-width: $screenTablet) {
    @content;
  }
}

@mixin media-desktop {
  @media (min-width: $screenDesktop) {
    @content;
  }
}
