@import "../../shared/styles/media";

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
  box-shadow: 1px 3px 6px 4px #cccccc;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 14px;

  user-select: none;

  .sideBlock {
    display: flex;
    align-items: center;
    width: 20vw;

    &.leftBlock {
      justify-content: flex-start;
    }

    &.rightBlock {
      justify-content: flex-end;
    }
  }

  @include media-all-phones {
    justify-content: center;

    padding: 5px 0;

    .sideBlock {
      display: none;
    }
  }
}
