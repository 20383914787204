.preview {
    font-size: 14px;
    border: solid 1px rgba(47, 79, 79, 0.4);
    background-color: #f9f9f9;
    height: auto;
    font-family: monospace;
    min-height: 30px;
    max-height: 250px;
    background-size: 100% 2rem;
    line-height: 19.6px;
    box-sizing: border-box;
    cursor: text;

    word-wrap: break-word;
    word-break: normal;
    white-space: pre-wrap;

    margin: 0;
    padding: 4px 2px 4px 6px;

    overflow: auto;
}
